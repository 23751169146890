import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'reactstrap';
import _ from 'lodash';
import styles from './styles.module.css';
import HttpErrorHandler from '../../components/http-error-handler';
import withAPI from '../../hocs/with-api';
import OrderDetails from '../../components/order-details';
import PrimaryButtonWithIcon from '../../components/primary-button-with-icon';
import SuccessAlert from '../../components/success-alert';

class PickOrders extends Component {
  constructor() {
    super();
    this.state = {
      orderDetails: null,
      orderPickedOk: false,
      orderRefusedOk: false,
      showOrderRefusedOkMessage: false,
    };
  }

  onLoginClick = () => {
    const { history } = this.props;
    history.push('/login');
  }

  componentDidMount() {
    this.fetchOrderDetails();
  }

  fetchOrderDetails = async () => {
    const { httpRequest, match } = this.props;
    const { id, hash } = match.params;

    const response = await httpRequest({
      method: 'get',
      url: `ao/${id}/${hash}`,
    }).catch(() => {});

    if (response) {
      const order = response.data;
      this.setState({ orderDetails: order });
    }
  }

  onPickOrderButtonClick = async () => {
    const { httpRequest, match } = this.props;
    const { id, hash } = match.params;
    this.setState({ orderPickedOk: false });
    const pickResponse = await httpRequest({
      method: 'get',
      url: `ao/pick/${id}/${hash}`,
      tag: 'pick',
    });

    if (pickResponse) {
      this.setState({ orderPickedOk: true });
    } else {
      this.setState({ orderPickedOk: false });
    }
  }

  onRefuseOrderButtonClick = async () => {
    const { httpRequest, match } = this.props;
    const { id, hash } = match.params;
    this.setState({ orderPickedOk: false });
    this.setState({ orderRefusedOk: true });
    const refuseResponse = await httpRequest({
      method: 'get',
      url: `ao/refuse/${id}/${hash}`,
      tag: 'refuse',
    });
    if (refuseResponse) {
      this.setState({ orderRefusedOk: true });
      this.setState({ showOrderRefusedOkMessage: true });
    } else {
      this.setState({ orderRefusedOk: false });
    }
  }

  render() {
    const {
      httpGetLoadingRefuse,
      httpGetLoadingPick,
      httpGetFailed,
      httpGetFailReason,
      t,
      httpGetFailedPick,
      httpGetFailReasonPick,
      httpGetFailedRefuse,
      httpGetFailReasonRefuse,
    } = this.props;
    const {
      orderDetails,
      orderPickedOk,
      orderRefusedOk,
      showOrderRefusedOkMessage,
    } = this.state;

    const canPickOrder = orderDetails !== null;
    const withRefuseButton = orderDetails !== null;
    const shouldDisablePickButton = httpGetLoadingPick
    || !canPickOrder
    || orderRefusedOk
    || orderPickedOk;
    const isRefuseOrderButtonDisabled = httpGetLoadingRefuse
    || httpGetLoadingPick
    || orderRefusedOk
    || orderPickedOk;

    return (
      <div className={`flex-start position-ref full-height page-container ${styles.grid}`}>
        <Row className={styles.alignCenter}>
          <div className={styles.container}>
            <Row className={`${styles.fullWidth}`}>
              <Col className={styles.logoContainer} lg="50">
                <div className={styles.logo}>
                  <img src="/assets/lfg-logo.png" alt="Logo" />
                </div>
                <div className={styles.logoText}>
                  { t('lf.logoBlock.name') }
                </div>
              </Col>
              <Col className={styles.buttonContainer}>
                <PrimaryButtonWithIcon
                  onClick={this.onLoginClick}
                  title={t('login')}
                  width="200px"
                  height="44px"
                  icon="/assets/images/lock-icon@2x.png"
                />
              </Col>
            </Row>

            <div className={`${styles.formContainer} ${styles.row}`}>
              {
                orderPickedOk && <SuccessAlert message={t('orders.picked')} />
              }
              {
                showOrderRefusedOkMessage && <SuccessAlert message={t('orders.refused')} />
              }
              {
                httpGetFailed && httpGetFailReason && <HttpErrorHandler {...httpGetFailReason} />
              }
              {
                httpGetFailedPick && httpGetFailReasonPick && <HttpErrorHandler {...httpGetFailReasonPick} />
              }
              {
                httpGetFailedRefuse && httpGetFailReasonRefuse && <HttpErrorHandler {...httpGetFailReasonRefuse} />
              }
            </div>
          </div>
        </Row>

        <Row className="rowWrapper">
          <div className={styles.lgContainer}>
            <Col className={styles.formCol}>
              {
                orderDetails
                && (
                <OrderDetails
                  withPickOrderButton={canPickOrder}
                  isPickOrderButtonDisabled={shouldDisablePickButton}
                  onPickOrderButtonClick={this.onPickOrderButtonClick}
                  withRefuseOrderButton={withRefuseButton}
                  onRefuseOrderButtonClick={this.onRefuseOrderButtonClick}
                  isRefuseOrderButtonDisabled={isRefuseOrderButtonDisabled}
                  shouldShowLessInfo={_.get(orderDetails, 'status.name', '') === 'Delivered'}
                  style={{ padding: '0px' }}
                  {...orderDetails}
                />
                )
              }
            </Col>
          </div>
        </Row>
        <Row>
          <Col md="12">
            <p className="copyright-text">
            Copyright ©
              {(new Date().getFullYear())}
          , Lafarge
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

PickOrders.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetLoading: PropTypes.bool.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  httpGetFailedPick: PropTypes.bool,
  httpGetLoadingPick: PropTypes.bool,
  httpGetFailReasonPick: PropTypes.object,
  httpGetFailed: PropTypes.bool,
  withRefuseButton: PropTypes.bool,
  httpGetLoadingRefuse: PropTypes.bool,
  httpGetFailReason: PropTypes.any,
  httpGetFailedRefuse: PropTypes.bool,
  httpGetFailReasonRefuse: PropTypes.any,
};

PickOrders.defaultProps = {
  httpGetFailedPick: false,
  httpGetLoadingPick: false,
  httpGetLoadingRefuse: false,
  httpGetFailReasonPick: undefined,
  httpGetFailed: false,
  httpGetFailReason: undefined,
  withRefuseButton: false,
  httpGetFailedRefuse: false,
  httpGetFailReasonRefuse: undefined,
};

export default compose(
  withTranslation(),
  withRouter,
  withAPI,
)(PickOrders);
