import React from 'react';
import PropTypes from 'prop-types';

const ReportCarrierOrdersAverageRow = ({ item, ...rest }) => (
  <tr {...rest}>
    <td>{item.name}</td>
    <td>{item.tons_orders_avg ?? '-'}</td>
    <td>{item.truck_orders_avg ?? '-'}</td>
  </tr>
);


ReportCarrierOrdersAverageRow.propTypes = {
  item: PropTypes.object.isRequired,
  rest: PropTypes.object,
};

ReportCarrierOrdersAverageRow.defaultProps = {
  rest: {},
};
export default ReportCarrierOrdersAverageRow;
