/* eslint max-len: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';

import withUserInfo from '../../hocs/with-user-info';

export const viewUsers = 'viewUsers';
export const addUsers = 'addUsers';
export const editUsers = 'editUsers';

export const viewCarriers = 'viewCarriers';
export const addCarriers = 'addCarriers';
export const editCarriers = 'editCarriers';
export const deleteCarriers = 'deleteCarriers';

export const viewDrivers = 'viewDrivers';
export const addDrivers = 'addDrivers';
export const editDrivers = 'editDrivers';
export const deleteDrivers = 'deleteDrivers';

export const viewVehicles = 'viewVehicles';
export const addVehicles = 'addVehicles';
export const editVehicles = 'editVehicles';
export const deleteVehicles = 'deleteVehicles';

export const viewWarehouses = 'viewWarehouses';
export const addWarehouses = 'addWarehouses';
export const editWarehouses = 'editWarehouses';
export const deleteWarehouses = 'deleteWarehouses';

export const viewOrdersImportStatus = 'viewOrdersImportStatus';
export const viewOrders = 'viewOrders';
export const viewAllOrders = 'viewAllOrders';
export const viewPersonalOrders = 'viewPersonalOrders';
export const viewNewOrders = 'viewNewOrders';
export const editOrders = 'editOrders';
export const editOrderDetails = 'editOrderDetails';
export const addOrders = 'addOrders';
export const pickOrders = 'pickOrders';
export const importOrders = 'importOrders';

export const everything = 'everything';

const abilities = {
  administrator: {
    everything,

    viewUsers,
    addUsers,
    editUsers,

    viewCarriers,
    addCarriers,
    editCarriers,
    deleteCarriers,

    viewVehicles,
    addVehicles,
    editVehicles,
    deleteVehicles,

    viewDrivers,
    addDrivers,
    editDrivers,
    deleteDrivers,

    viewWarehouses,
    addWarehouses,
    editWarehouses,
    deleteWarehouses,

    viewOrders,
    viewAllOrders,
    viewPersonalOrders,
    viewNewOrders,
    addOrders,
    importOrders,
    editOrders,

    viewOrdersImportStatus,
    pickOrders,
    editOrderDetails,
  },

  planner: {
    viewUsers,
    viewVehicles,
    viewDrivers,
    viewCarriers,
    viewWarehouses,

    addDrivers,
    editDrivers,
    editVehicles,

    viewOrders,
    viewAllOrders,
    viewPersonalOrders,
    viewNewOrders,
    importOrders,
    editOrders,
    viewOrdersImportStatus,
    pickOrders,
  },

  call_center: {
    viewCarriers,
    viewDrivers,
    viewVehicles,
    viewWarehouses,
    viewOrders,
    viewAllOrders,

    editOrders,
    editVehicles,
    importOrders,
    viewOrdersImportStatus,
    viewPersonalOrders,
    viewNewOrders,
    pickOrders,
  },

  dispatcher: {

    viewVehicles,
    editVehicles,
    deleteVehicles,
    viewWarehouses,

    addDrivers,
    viewDrivers,
    editDrivers,
    deleteDrivers,

    viewOrders,
    viewPersonalOrders,
    viewNewOrders,
    editOrderDetails,
    pickOrders,
  },
};

const hasPermission = (permission, identifier) => _.isString(_.get(abilities, `${identifier}.${permission}`, null));

const WithPermission = ({
  permission,
  children,
  user,
  onRoute,
}) => {
  const identifier = _.get(user, 'roles[0].identifier');
  const multiplePermissionRequired = _.isArray(permission);
  const singlePermissionGranted = !multiplePermissionRequired && hasPermission(permission, identifier);
  const multiplePermissionGranted = multiplePermissionRequired && _.find(permission, (it) => hasPermission(it, identifier));
  if (multiplePermissionGranted) {
    console.info(`Granted multiple permission on ${multiplePermissionGranted}`);
  }
  const intentAllowed = singlePermissionGranted || multiplePermissionGranted;
  if (!intentAllowed) return onRoute ? <Redirect to="/dashboard" /> : null;
  return children;
};

WithPermission.propTypes = {
  permission: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  children: PropTypes.node.isRequired,
  user: PropTypes.object.isRequired,
  onRoute: PropTypes.bool,
};

WithPermission.defaultProps = {
  onRoute: false,
};

export default compose(
  withUserInfo,
)(WithPermission);
