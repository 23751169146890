import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Col } from 'reactstrap';
import Spinner from '../../components/spinner';
import HttpError from '../../components/http-error';
import withAPI from '../../hocs/with-api';
import '../../date-picker.css';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '../../components/table';
import styles from './styles.module.css';
import ReportCarrierOrdersAverageRow from '../../components/report-carrier-orders-average-row';

const firstDate = moment().subtract(1, 'months').toDate();
class ReportCarrierOrdersAverage extends PureComponent {
  constructor() {
    super();
    this.state = {
      reportData: {},
      selectedDate: firstDate,
      date: {
        month: moment(firstDate).month() + 1,
        year: moment(firstDate).year(),
      },
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    document.title = `${t('reports.avgOrdersReport')} - ${t('app.name')}`;
    this.loadReportData();
  }

  loadReportData = async () => {
    const { httpRequest } = this.props;
    const { date } = this.state;
    const formattedMonth = String(date.month).padStart(2, '0');
    const response = await httpRequest({
      method: 'get',
      url: `statistics/total/orders/uom/monthly?month=${date.year}-${formattedMonth}`,
    });

    if (response) {
      const {
        data: reportData,
      } = response;
      this.setState({
        reportData,
      });
    }
  };

  handleSetDateFrom = (date) => {
    this.setState(
      {
        selectedDate: date,
        date: {
          month: moment(date).month() + 1,
          year: moment(date).year(),
        },
      },
      () => this.loadReportData(),
    );
  };


  render() {
    const {
      t, httpGetDone, httpGetLoading, httpGetFailed,
    } = this.props;
    const { reportData, selectedDate } = this.state;

    return (
      <>
        <Col md="6">
          <p className={`lf-main-text inline-block ${styles.head}`}>
            {t('reports.avgOrdersReport')}
          </p>
        </Col>
        <Col md="6" className="text-right">
          <div className="inline-block">
            <DatePicker
              onChange={this.handleSetDateFrom}
              selected={selectedDate}
              className="input-clean center-text kpi-date-picker"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              maxDate={moment().toDate()}
            />
          </div>
        </Col>
        <Col md="12">
          {httpGetLoading && <Spinner />}
          {httpGetFailed && (
            <HttpError withTryAgainButton onTryAgain={this.loadReportData} />
          )}
          {!httpGetLoading && !httpGetFailed && httpGetDone && (
            <>
              <Table
                hover
                responsive="sm"
                size="sm"
                isEmpty={_.isEmpty(_.get(reportData, 'data', []))}
              >
                <thead>
                  <tr>
                    <th>{t('carrier')}</th>
                    <th>
                      {t('reports.tons_orders_avg')}
                    </th>
                    <th>
                      {t('reports.truck_orders_avg')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.get(reportData, 'avgData', undefined)
                    && (
                    <ReportCarrierOrdersAverageRow
                      item={_.get(reportData, 'avgData')}
                      style={{ fontWeight: 'bold' }}
                    />
                    )
                  }
                  {_.map(_.get(reportData, 'data'), (itemData, index) => (
                    <ReportCarrierOrdersAverageRow key={`itemDataRow-${index}`} item={itemData} />
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Col>
      </>
    );
  }
}

ReportCarrierOrdersAverage.propTypes = {
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
};

ReportCarrierOrdersAverage.defaultProps = {
  httpGetLoading: true,
  httpGetFailed: false,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(ReportCarrierOrdersAverage);
