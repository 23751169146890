import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import './styles.css';

import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  Collapse,
} from 'reactstrap';

import WithPermission, {
  addDrivers,
  addUsers,
  addVehicles,
  addCarriers,
  addWarehouses,
  viewUsers,
  viewVehicles,
  viewDrivers,
  viewWarehouses,
  viewCarriers,
  viewOrders,
  viewAllOrders,
  viewPersonalOrders,
  viewNewOrders,
  everything,
  editCarriers,
} from '../../modules/with-permission';

import withUserInfo from '../../hocs/with-user-info';

import LeftNavigationItem, { togglerStyle, navLinkStyle, UserInfoNavigationItem } from '../left-navigation-item';

const togglers = {
  orders: 'orders',
  carriers: 'carriers',
  vehicles: 'vehicles',
  drivers: 'drivers',
  clients: 'clients',
  kpi: 'kpi',
  warehouses: 'warehouses',
  users: 'users',
  issues: 'issues',
  reports: 'reports',
};

const SideMenu = ({
  t,
  isAuthenticated,
  user,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
    document.getElementById('root').classList.add('fixed-position');
  };

  const onExited = () => document.getElementById('root').classList.remove('fixed-position');

  const [toggledMenuItem, setOpenItem] = React.useState('');
  const onItemToggle = (event) => {
    setOpenItem(event.target.id === toggledMenuItem ? '' : event.target.id);
  };

  const onNavigate = () => {
    setIsOpen(false);
  };
  const menuItemIsOpened = (toggler) => toggledMenuItem === toggler;

  return (
    <>
      <Navbar light className="navbar navbar-expand-md fixed-left">
        <div className="navbar-header">
          <NavbarToggler onClick={toggle} />
          <NavbarBrand href="/dashboard">
            <img src="/assets/lfg-logo.png" height="60px" width="132px" className="logoImg" alt="logo" />
          </NavbarBrand>
        </div>

        <Collapse
          onExited={onExited}
          isOpen={isOpen}
          navbar
          className="collapse-container"
        >
          <Nav className="side-menu-container navbar-collapse" navbar vertical>
            {
              isAuthenticated && (
                <>
                  <LeftNavigationItem
                    title="Dashboard"
                    path="/dashboard"
                    icon="/assets/dashboard-icon.png"
                    isParent
                    onRouteChanged={onNavigate}
                  />
                  {/* Orders */}
                  <WithPermission permission={viewOrders}>
                    <LeftNavigationItem
                      icon="/assets/orders-icon.png"
                      id={togglers.orders}
                      title={t('orders.all')}
                      canNavigate={false}
                      onClick={onItemToggle}
                      onRouteChanged={onNavigate}
                    />
                    <li style={navLinkStyle}>
                      <Collapse isOpen={menuItemIsOpened(togglers.orders)} style={togglerStyle}>
                        <WithPermission permission={viewAllOrders}>
                          <LeftNavigationItem
                            canNavigate
                            title={t('orders.viewAll')}
                            path="/orders"
                            onRouteChanged={onNavigate}
                          />
                        </WithPermission>
                        <WithPermission permission={viewPersonalOrders}>
                          <LeftNavigationItem
                            canNavigate
                            title={t('orders.allocated.navbar')}
                            path="/my-orders/recommended"
                            onRouteChanged={onNavigate}
                          />
                        </WithPermission>
                        <WithPermission permission={viewNewOrders}>
                          <LeftNavigationItem
                            canNavigate
                            title={t('orders.free.navbar')}
                            path="/my-orders/additional"
                            onRouteChanged={onNavigate}
                          />
                        </WithPermission>
                        <WithPermission permission={viewPersonalOrders}>
                          <LeftNavigationItem
                            canNavigate
                            title={t('orders.mine')}
                            path="/my-orders/history"
                            onRouteChanged={onNavigate}
                          />
                        </WithPermission>
                      </Collapse>
                    </li>
                  </WithPermission>
                  {/* Orders End */}

                  {/* Carriers  */}
                  {
                    !user.isDispatcher && (
                      <>
                        <LeftNavigationItem
                          icon="/assets/carriers-icon.png"
                          id={togglers.carriers}
                          title={t('carriers.all')}
                          canNavigate={false}
                          onClick={onItemToggle}
                          onRouteChanged={onNavigate}
                        />
                        <li style={navLinkStyle}>
                          <Collapse isOpen={menuItemIsOpened(togglers.carriers)}>
                            <WithPermission permission={viewCarriers}>
                              <LeftNavigationItem
                                title={t('carriers.viewAll')}
                                path="/carriers"
                                onRouteChanged={onNavigate}
                              />
                              <WithPermission permission={addCarriers}>
                                <LeftNavigationItem
                                  title={t('carriers.createNew')}
                                  path="/carriers/new"
                                  onRouteChanged={onNavigate}
                                />
                              </WithPermission>
                              <WithPermission permission={editCarriers}>
                                <LeftNavigationItem
                                  title={t('carriers.suspendedCarriers')}
                                  path="/suspended-carriers"
                                  onRouteChanged={onNavigate}
                                />
                              </WithPermission>
                            </WithPermission>
                          </Collapse>
                        </li>
                      </>
                    )

                  }
                  {/* Carriers End */}

                  {/* Drivers */}
                  <LeftNavigationItem
                    icon="/assets/drivers-icon.png"
                    id={togglers.drivers}
                    title={t('drivers.all')}
                    canNavigate={false}
                    onClick={onItemToggle}
                    onRouteChanged={onNavigate}
                  />
                  <li style={navLinkStyle}>
                    <Collapse isOpen={menuItemIsOpened(togglers.drivers)}>
                      <WithPermission permission={viewDrivers}>
                        <LeftNavigationItem title={t('drivers.viewAll')} path="/drivers" onRouteChanged={onNavigate} />
                        <WithPermission permission={addDrivers}>
                          <LeftNavigationItem
                            title={t('drivers.createNew')}
                            path="/drivers/new"
                            onRouteChanged={onNavigate}
                          />
                        </WithPermission>
                      </WithPermission>

                    </Collapse>
                  </li>
                  {/* Drivers End */}

                  {/* Vehicles */}
                  <LeftNavigationItem
                    icon="/assets/vehicles-icon.png"
                    id={togglers.vehicles}
                    title={t('vehicles.all')}
                    canNavigate={false}
                    onClick={onItemToggle}
                    onRouteChanged={onNavigate}
                  />
                  <li style={navLinkStyle}>
                    <Collapse isOpen={menuItemIsOpened(togglers.vehicles)}>
                      <WithPermission permission={viewVehicles}>
                        <LeftNavigationItem
                          title={t('vehicles.viewAll')}
                          path="/vehicles"
                          onRouteChanged={onNavigate}
                        />
                        <WithPermission permission={addVehicles}>
                          <LeftNavigationItem
                            title={t('vehicles.createNew')}
                            path="/vehicles/new"
                            onRouteChanged={onNavigate}
                          />
                        </WithPermission>
                        <WithPermission permission={everything}>
                          <LeftNavigationItem
                            title={t('vehicles.statuses')}
                            path="/vehicles/statuses"
                            onRouteChanged={onNavigate}
                          />
                        </WithPermission>
                      </WithPermission>

                    </Collapse>
                  </li>
                  {/* Vehicles End */}

                  {/* TODO: set permission properly  */}
                  {/* Clients  */}
                  <WithPermission permission={everything}>
                    <LeftNavigationItem
                      id={togglers.clients}
                      icon="/assets/clients-icon.png"
                      title={t('clients.all')}
                      canNavigate={false}
                      onClick={onItemToggle}
                      onRouteChanged={onNavigate}
                    />
                    <li style={navLinkStyle}>
                      <Collapse isOpen={menuItemIsOpened(togglers.clients)}>
                        <LeftNavigationItem title={t('clients.viewAll')} path="/clients" onRouteChanged={onNavigate} />
                      </Collapse>
                    </li>
                  </WithPermission>
                  {/* Clients End */}

                  {/* Warehouses */}
                  <WithPermission permission={viewWarehouses}>
                    <LeftNavigationItem
                      id={togglers.warehouses}
                      icon="/assets/warehouses-icon.png"
                      title={t('warehouses.all')}
                      canNavigate={false}
                      onClick={onItemToggle}
                      onRouteChanged={onNavigate}
                    />
                    <li style={navLinkStyle}>
                      <Collapse isOpen={menuItemIsOpened(togglers.warehouses)}>
                        <LeftNavigationItem
                          title={t('warehouses.viewAll')}
                          path="/warehouses"
                          onRouteChanged={onNavigate}
                        />
                        <WithPermission permission={addWarehouses}>
                          <LeftNavigationItem
                            title={t('warehouses.createNew')}
                            path="/warehouses/new"
                            onRouteChanged={onNavigate}
                          />
                        </WithPermission>
                      </Collapse>
                    </li>
                  </WithPermission>
                  {/* Warehouses End */}

                  {/* KPI */}
                  <WithPermission permission={everything}>
                    <LeftNavigationItem
                      id={togglers.kpi}
                      icon="/assets/kpi-icon.png"
                      title={t('kpi.all')}
                      canNavigate={false}
                      onClick={onItemToggle}
                      onRouteChanged={onNavigate}
                    />
                    <li style={navLinkStyle}>
                      <Collapse isOpen={menuItemIsOpened(togglers.kpi)}>
                        <WithPermission permission={everything}>
                          <LeftNavigationItem
                            title={t('kpi.history')}
                            path="/kpi/history"
                            onRouteChanged={onNavigate}
                          />
                        </WithPermission>
                        <WithPermission permission={everything}>
                          <LeftNavigationItem title={t('kpi.viewAll')} path="/kpi" onRouteChanged={onNavigate} />
                        </WithPermission>
                      </Collapse>
                    </li>
                  </WithPermission>
                  {/* KPI End */}

                  {/* Reports */}
                  <WithPermission permission={everything}>
                    <LeftNavigationItem
                      id={togglers.reports}
                      icon="/assets/reports-icon.png"
                      title={t('reports.all')}
                      canNavigate={false}
                      onClick={onItemToggle}
                      onRouteChanged={onNavigate}
                    />
                    <li style={navLinkStyle}>
                      <Collapse isOpen={menuItemIsOpened(togglers.reports)}>
                        <WithPermission permission={everything}>
                          <LeftNavigationItem
                            title={t('reports.avgOrdersReport')}
                            path="/reports/carrier-orders-average-by-month"
                            onRouteChanged={onNavigate}
                          />
                        </WithPermission>
                      </Collapse>
                    </li>
                  </WithPermission>
                  {/* Reports End */}


                  {/* Users */}
                  <WithPermission permission={viewUsers}>
                    <LeftNavigationItem
                      id={togglers.users}
                      icon="/assets/users-icon.png"
                      title={t('users.all')}
                      canNavigate={false}
                      onClick={onItemToggle}
                      onRouteChanged={onNavigate}
                    />
                    <li style={navLinkStyle}>
                      <Collapse isOpen={menuItemIsOpened(togglers.users)}>
                        <LeftNavigationItem title={t('users.viewAll')} path="/users" onRouteChanged={onNavigate} />
                        <WithPermission permission={addUsers}>
                          <LeftNavigationItem
                            title={t('users.createNew')}
                            path="/users/new"
                            onRouteChanged={onNavigate}
                          />
                        </WithPermission>
                      </Collapse>
                    </li>
                  </WithPermission>
                  {/* Users End */}

                  {/* Issues */}
                  <WithPermission permission={everything}>
                    <LeftNavigationItem
                      id={togglers.issues}
                      icon="/assets/issues-icon.png"
                      title={t('issues.title')}
                      canNavigate={false}
                      onClick={onItemToggle}
                      onRouteChanged={onNavigate}
                    />
                    <li style={navLinkStyle}>
                      <Collapse isOpen={menuItemIsOpened(togglers.issues)}>
                        <LeftNavigationItem title={t('issues.titleView')} path="/issues" onRouteChanged={onNavigate} />
                      </Collapse>
                    </li>
                  </WithPermission>
                  {/* Issues End */}

                  {/* Working program */}
                  {
                    user.isAdmin && (
                      <LeftNavigationItem
                        title={t('work_program.title')}
                        path="/work-program"
                        icon="/assets/datepicker-icon.png"
                        isParent
                        onRouteChanged={onNavigate}
                      />
                    )
                  }
                  {/* Working program end */}

                  {
                    isAuthenticated && (
                    <UserInfoNavigationItem
                      t={t}
                      user={user}
                      onNavigate={onNavigate}
                    />
                    )
                  }
                </>
              )
            }
          </Nav>
        </Collapse>

      </Navbar>
    </>
  );
};

SideMenu.propTypes = {
  t: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  withUserInfo,
  React.memo,
  withRouter,
)(SideMenu);
