import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  Container, Row,
} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/ro';
import { Route, Switch, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import HomepageCard from '../../components/homepage-card';
import NavigationBar from '../../components/navigation-bar';

import Carriers from '../carriers';
import SuspendedCarriers from '../suspended-carriers';
import EditCarrier from '../edit-carrier';
import CreateCarrier from '../create-carrier';

import Users from '../users';
import CreateUser from '../create-user';
import EditUser from '../edit-user';
import Clients from '../clients';
import Drivers from '../drivers';
import CreateDriver from '../create-driver';
import EditDriver from '../edit-driver';

import Vehicles from '../vehicles';
import VehicleStatuses from '../vehicles-statuses';
import CreateVehicle from '../create-vehicle';
import EditVehicle from '../edit-vehicle';

import ImportStatus from '../import-status';
import Footer from '../../components/footer';

import Warehouses from '../warehouses';
import EditWarehouse from '../edit-warehouse';
import EditOrder from '../edit-order';
import Orders from '../orders';
import MyOrders from '../my-orders';
import ViewOrder from '../view-order';
import ChangePassword from '../change-password';
import CreateWarehouse from '../create-warehouses';
import EditClient from '../edit-client';
import KPI from '../kpi';
import EditKpi from '../edit-kpi';
import EditOrderDetails from '../edit-order-details';
import SideMenu from '../../components/side-menu';
import KpiHistory from '../kpi-history';
import Issues from '../issues';
import WorkProgram from '../work-program';
import WithPermission, {
  addVehicles,
  addDrivers,
  addUsers,
  addCarriers,

  editUsers,
  editCarriers,
  editDrivers,
  editVehicles,
  editWarehouses,
  addWarehouses,

  viewUsers,
  viewCarriers,
  viewVehicles,
  viewDrivers,

  viewWarehouses,
  everything,
  viewOrders,
  viewPersonalOrders,
  editOrderDetails,
  viewOrdersImportStatus,
} from '../with-permission';

import withUserInfo from '../../hocs/with-user-info';
import withEcho from '../../hocs/with-echo';
import {
  askForNotificationPermission,
  showBrowserNotification,
} from '../../services/browser-notifications';
import ReportCarrierOrdersAverage from '../report-carrier-orders-average';


moment.locale('ro');

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    props.echoClient
      .channel(`orders.index.${props.user.id}`)
      .listen('ImportFinished', this.onImportFinished);
  }

  componentDidMount() {
    askForNotificationPermission();
  }

  componentWillUnmount() {
    const { echoClient } = this.props;
    echoClient.leave('ImportFinished');
    echoClient.leave('OrderPicked');
  }

  onOrderPicked = () => {}

  onImportFinished = (event) => {
    const { t } = this.props;
    const { data: { import_id, success } } = event;
    showBrowserNotification({
      title: t('import.good.title'),
      body: success ? t('import.good', event.data) : t('import.bad'),
      onClickCallback: () => window.open(`/import/${import_id}/status`),
    });
  }

  render() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) return <Redirect to="/login" />;
    const { location: { hash, search } } = this.props;
    if (hash || search) return <Redirect to="/dashboard" />;
    return (
      <>
        <div id="app">
          <SideMenu />
          <NavigationBar />
          <Container>
            <main className="py-4">
              <Row className="justify-content-center rowWrapper">
                <Switch>
                  { /* Users Start */ }
                  <Route exact path="/users/new">
                    <WithPermission onRoute permission={addUsers}>
                      <CreateUser />
                    </WithPermission>
                  </Route>

                  <Route exact path="/users/:id/edit">
                    <WithPermission onRoute permission={editUsers}>
                      <EditUser />
                    </WithPermission>
                  </Route>

                  <Route exact path="/users/:page(\d+)?">
                    <WithPermission onRoute permission={viewUsers}>
                      <Users />
                    </WithPermission>
                  </Route>
                  { /* Users End */ }

                  { /* Clients Start */ }
                  <Route exact path="/clients/:id(\d+)/edit">
                    <WithPermission onRoute permission={everything}>
                      <EditClient />
                    </WithPermission>
                  </Route>

                  <Route exact path="/clients/:page(\d+)?">
                    <WithPermission onRoute permission={everything}>
                      <Clients />
                    </WithPermission>
                  </Route>
                  { /* Clients End */ }

                  { /* Carriers Start */ }
                  <Route exact path="/carriers/new">
                    <WithPermission onRoute permission={addCarriers}>
                      <CreateCarrier />
                    </WithPermission>
                  </Route>

                  <Route exact path="/carriers/:id(\d+)/edit">
                    <WithPermission onRoute permission={editCarriers}>
                      <EditCarrier />
                    </WithPermission>
                  </Route>

                  <Route exact path="/carriers/:page(\d+)?">
                    <WithPermission onRoute permission={viewCarriers}>
                      <Carriers />
                    </WithPermission>
                  </Route>

                  <Route exact path="/suspended-carriers/:page(\d+)?">
                    <WithPermission onRoute permission={editCarriers}>
                      <SuspendedCarriers />
                    </WithPermission>
                  </Route>
                  { /* Carriers End */ }

                  { /* Transports Start */ }
                  <Route exact path="/vehicles/new">
                    <WithPermission onRoute permission={addVehicles}>
                      <CreateVehicle />
                    </WithPermission>
                  </Route>
                  <Route exact path="/vehicles/:id(\d+)/edit">
                    <WithPermission onRoute permission={editVehicles}>
                      <EditVehicle />
                    </WithPermission>
                  </Route>
                  <Route exact path="/vehicles/:page(\d+)?">
                    <WithPermission onRoute permission={viewVehicles}>
                      <Vehicles />
                    </WithPermission>
                  </Route>
                  <Route exact path="/vehicles/statuses">
                    <WithPermission onRoute permission={everything}>
                      <VehicleStatuses />
                    </WithPermission>
                  </Route>
                  { /* Transports End */ }

                  { /* Drivers Start */ }
                  <Route exact path="/drivers/new">
                    <WithPermission onRoute permission={addDrivers}>
                      <CreateDriver />
                    </WithPermission>
                  </Route>
                  <Route exact path="/drivers/:id(\d+)/edit">
                    <WithPermission onRoute permission={editDrivers}>
                      <EditDriver />
                    </WithPermission>
                  </Route>
                  <Route exact path="/drivers/:page(\d+)?">
                    <WithPermission onRoute permission={viewDrivers}>
                      <Drivers />
                    </WithPermission>
                  </Route>
                  { /* Drivers End */ }

                  { /* Warehouses Start */ }
                  <Route exact path="/warehouses/new">
                    <WithPermission onRoute permission={addWarehouses}>
                      <CreateWarehouse />
                    </WithPermission>
                  </Route>
                  <Route exact path="/warehouses/:id(\d+)/edit">
                    <WithPermission onRoute permission={editWarehouses}>
                      <EditWarehouse />
                    </WithPermission>
                  </Route>
                  <Route exact path="/warehouses/:page(\d+)?">
                    <WithPermission onRoute permission={viewWarehouses}>
                      <Warehouses />
                    </WithPermission>
                  </Route>
                  { /* Warehouses End */ }

                  { /* Orders Start */ }
                  <Route exact path="/my-orders/:tabId/:page(\d+)?">
                    <WithPermission onRoute permission={viewPersonalOrders}>
                      <MyOrders />
                    </WithPermission>
                  </Route>
                  <Route exact path="/orders/:id(\d+)/edit">
                    <WithPermission onRoute permission={viewOrders}>
                      <EditOrder />
                    </WithPermission>
                  </Route>
                  <Route exact path="/orders/:id(\d+)/details/edit">
                    <WithPermission onRoute permission={editOrderDetails}>
                      <EditOrderDetails />
                    </WithPermission>
                  </Route>
                  <Route exact path="/orders/:id(\d+)/view">
                    <WithPermission onRoute permission={viewOrders}>
                      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY_V3}>
                        <ViewOrder />
                      </GoogleReCaptchaProvider>
                    </WithPermission>
                  </Route>
                  <Route exact path="/orders/:page(\d+)?">
                    <WithPermission onRoute permission={viewOrders}>
                      <Orders />
                    </WithPermission>
                  </Route>
                  { /* Orders End */ }

                  { /* OrderImport Start */ }
                  <Route exact path="/import/:id(\d+)/status">
                    <WithPermission onRoute permission={viewOrdersImportStatus}>
                      <ImportStatus />
                    </WithPermission>
                  </Route>
                  { /* OrderImport End */ }

                  <Route exact path="/kpi/edit">
                    <WithPermission onRoute permission={everything}>
                      <EditKpi />
                    </WithPermission>
                  </Route>
                  <Route exact path="/kpi/history">
                    <WithPermission onRoute permission={everything}>
                      <KpiHistory />
                    </WithPermission>
                  </Route>
                  <Route exact path="/kpi/">
                    <WithPermission onRoute permission={everything}>
                      <KPI />
                    </WithPermission>
                  </Route>

                  <Route exact path="/reports/carrier-orders-average-by-month">
                    <WithPermission onRoute permission={everything}>
                      <ReportCarrierOrdersAverage />
                    </WithPermission>
                  </Route>

                  <Route exact path="/issues">
                    <WithPermission onRoute permission={everything}>
                      <Issues />
                    </WithPermission>
                  </Route>

                  <Route exact path="/work-program">
                    <WithPermission onRoute permission={everything}>
                      <WorkProgram />
                    </WithPermission>
                  </Route>
                  { /* Dashboard End */ }

                  <Route exact path="/dashboard" component={HomepageCard} />
                  <Route exact path="/password/change" component={ChangePassword} />
                  <Redirect from="*" to="/dashboard" />
                </Switch>
              </Row>
            </main>
          </Container>
        </div>
        <Footer />
      </>
    );
  }
}

Dashboard.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  echoClient: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  withUserInfo,
  withEcho,
)(Dashboard);
